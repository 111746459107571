<template>
  <a-drawer
    :title="viewFormType == 0 ? '添加用户' : '修改用户'"
    :maskClosable="false"
    width="600"
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="userEditVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form-model
      ref="ruleForm"
      :validateOnRuleChange="true"
      :model="userForm"
      :rules="userRules"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-model-item label="工号" prop="empno">
        <a-input
          @blur="handleUserUniqueBlur('empno')"
          v-model="userForm.empno"
          style="width: 50%"
        />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="username">
        <a-input
          @blur="handleUserUniqueBlur('username')"
          v-model="userForm.username"
          style="width: 50%"
        />
      </a-form-model-item>
      <a-form-model-item label="英文名" prop="usernameEn">
        <a-input
          @blur="handleUserUniqueBlur('usernameEn')"
          v-model="userForm.usernameEn"
          style="width: 80%">
         </a-input>
        <a-button type="dashed" v-if="false" shape="circle" icon="search" @click="changeUsername" />
      </a-form-model-item>
      <a-form-model-item v-if="viewFormType == 0" label="密码" prop="password">
        <a-input
          v-model="userForm.password"
          defaultValue="123456"
          disabled
          type="password"
          style="width: 50%"
        />
        <span style="font-size: 12px; color: orange; margin-left: 10px"
          >默认密码为123456</span
        >
      </a-form-model-item>
      <a-form-model-item label="人员类型" prop="userType">
        <a-select
          v-model="userForm.userType"
          placeholder="选择类型"
          style="width: 50%"
        >
          <a-select-option
            v-for="(item, index) in userTypes"
            :key="index"
            :value="item.keyy"
          >
            {{ item.valuee }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="性别" prop="ssex">
        <a-select
          v-model="userForm.ssex"
          placeholder="选择性别"
          style="width: 30%"
        >
          <a-select-option
            v-for="(item, index) in ssexs"
            :key="index"
            :value="item.keyy"
          >
            {{ item.valuee }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email">
        <a-input v-model="userForm.email" />
      </a-form-model-item>
      <a-form-model-item label="学历" prop="eb">
        <a-select
          v-model="userForm.eb"
          placeholder="选择学历"
          style="width: 50%"
        >
          <a-select-option
            v-for="(item, index) in ebs"
            :key="index"
            :value="item.keyy"
          >
            {{ item.valuee }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="私人电话" prop="mobile">
        <a-input
          @blur="handleUserUniqueBlur('mobile')"
          v-model="userForm.mobile"
          style="width: 50%"
        />
      </a-form-model-item>
      <a-form-model-item label="固定电话" prop="fixPhone">
        <a-input v-model="userForm.fixPhone" style="width: 50%" />
      </a-form-model-item>
      <a-form-model-item label="公司电话" prop="officePhone">
        <a-input v-model="userForm.officePhone" style="width: 50%" />
      </a-form-model-item>
      <a-form-model-item label="住址" prop="address">
        <a-input v-model="userForm.address" />
      </a-form-model-item>
      <a-form-model-item label="部门" prop="deptId">
        <a-tree-select
          v-model="userForm.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptTreeData"
          placeholder="选择部门"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="职位" prop="deptPositionId">
        <a-select
          v-model="userForm.deptPositionId"
          :allowClear="true"
          style="width: 50%"
          placeholder="选择职位"
        >
          <a-select-option
            :value="dp.deptPositionId"
            v-for="dp in deptPositionListData"
            :key="dp.deptPositionId"
            >{{ dp.deptPositionName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="角色"
        v-if="deptPositionListData && deptPositionListData.length > 0"
        prop="roleId"
      >
        <a-select
          mode="multiple"
          :value="userFormRoleIdSplited"
          placeholder="选择角色"
          @change="
            (arr) => {
              userForm.roleId = arr.join(',');
            }
          "
        >
          <a-select-option
            v-for="(r, index) in roleDataFiltered"
            :value="String(r.roleId)"
            :key="index"
            >{{ r.roleName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="上属主管" prop="lineManager">
        <a-input v-model="userForm.lineManager" style="width: 50%" />
      </a-form-model-item>
      <a-form-model-item label="紧急联系人称谓" prop="emergency">
        <a-select
          v-model="userForm.emergency"
          placeholder="选择称谓"
          style="width: 50%"
        >
          <a-select-option
            v-for="(item, index) in emergencys"
            :key="index"
            :value="item.keyy"
          >
            {{ item.valuee }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="紧急联系人姓名" prop="emergencyContact">
        <a-input v-model="userForm.emergencyContact" style="width: 50%" />
      </a-form-model-item>
      <a-form-model-item label="紧急联系人电话" prop="emergencyPhone">
        <a-input v-model="userForm.emergencyPhone" style="width: 50%" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="userForm.description" type="textarea" />
      </a-form-model-item>
      <a-form-model-item label="个人称号" prop="emergency">
        <a-select
          mode="multiple"
          :value="personalTitleKeySplited"
          placeholder="选择个人称号"
          @change="
            (arr) => {
              userForm.personalTitleKey = arr.join(',');
            }
          "
        >
          <a-select-option
            v-for="(item, index) in personalTitleData"
            :key="index"
            :title="item.kkey"
            :value="item.kkey"
          >
            {{ `${item.note}` }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-select
          v-model="userForm.status"
          placeholder="选择状态"
          style="width: 50%"
        >
          <a-select-option
            v-for="(item, index) in statuss"
            :key="index"
            :value="item.keyy"
          >
            {{ item.valuee }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import pinyin from "js-pinyin";
import {getDeptRootId} from './../../../utils/tools'

import { mapState, mapMutations } from "vuex";
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
export default {
  name: "UserEdit",
  props: {
    userTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    emergencys: {
      type: Array,
      required: true,
      default: () => [],
    },
    ebs: {
      type: Array,
      required: true,
      default: () => [],
    },
    ssexs: {
      type: Array,
      required: true,
      default: () => [],
    },
    statuss: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      userEditVisiable: false,
      formItemLayout,
      userForm: {
        userType: undefined,
        empno: undefined,
        username: undefined,
        usernameEn: undefined,
        ssex: undefined,
        email: undefined,
        eb: undefined,
        mobile: undefined,
        fixPhone: undefined,
        officePhone: undefined,
        address: undefined,
        deptId: undefined,
        deptName: undefined,
        deptPositionId: undefined,
        deptPositionName: undefined,
        roleId: undefined,
        roleName: undefined,
        lineManager: undefined,
        emergency: undefined,
        emergencyContact: undefined,
        emergencyPhone: undefined,
        personalTitleKey: "Long_Service_Award_five",
        personalTitle: undefined,
        description: undefined,
        status: undefined,
      },
      userRules: {
        userType: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
        empno: [
          { required: true, message: "请填写员工编号", trigger: "blur" },
          {
            pattern:
              "^[a-zA-Z0-9]+$",
            message: "输入英文和数字",
            trigger: "change"
          },
          {
            min: 1,
            max: 12,
            message: "员工编号长度需在2到12之间",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请填写中文名", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "用户名长度需在2到15之间",
            trigger: "blur",
          },
        ],
        usernameEn: [
          {
            min: 2,
            max: 50,
            message: "英文长度需在2到50之间",
            trigger: "blur",
          },
        ],
        ssex: [{ required: true, message: "请选择性别", trigger: "change" }],
        email: [{ max: 50, message: "长度不能超过50个字符" }],
        eb: [{ required: true, message: "请选择学历", trigger: "change" }],
        fixPhone: [
        ],
        mobile: [
          {
            pattern:
              "^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$",
            message: "请输入正确的手机号",
            trigger: "change"
          },
        ],
        deptId: [{ required: true, message: "请选择部门", trigger: "change" }],
        deptPositionId: [
          { required: true, message: "请选择职位", trigger: "change" },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        emergencyPhone: [
          {
            pattern:
              "^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$",
            message: "请输入正确的手机号",
          },
        ],
        status: [
          { required: true, message: "请选择用户状态", trigger: "change" },
        ],
      },
      deptTreeData: [],
      deptPositionListData: [],
      roleData: [],
      personalTitleData: [],
      loading: false,
      backupData: undefined,
      viewFormType: 0, // 0:新建，1:修改
    };
  },
  mounted() {
    this.$get("dept", { rootDeptIds: this.topDeptId }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
    this.$get("role/list").then((r) => {
      this.roleData = r.data.data;
    });
    this.$get("biz-config/personal-title/list").then((r) => {
      this.personalTitleData = r.data;
    });
  },
  methods: {
    ...mapMutations({
      setUser: "account/setUser",
    }),
    handleUserUniqueBlur(type) {
      let value = this.userForm[type];
      if (value) {
        this.$get(`user/check/unique/${value}`).then((r) => {
          if (!r.data) {
            let msg = null
            switch(type) {
              case "username": msg = `用户名为${value}的用户已存在`;
              case "mobile": msg = `手机号为${value}的用户已存在`;
              case "empno": msg = `编号为${value}的用户已存在`;
            }

            this.$notification.error({
              message: '错误提示',
              description: msg,
            });
          }
        });
      }
    },
    setForm(model, user) {
      if (model) {
        if (!user.roleId) user.roleId = undefined;
        if (!user.roleName) user.roleName = undefined;
        this.backupData = user;
        const newObj = JSON.parse(JSON.stringify(this.backupData));
        if (!newObj.roleId) newObj.roleId = undefined;
        if (!newObj.roleName) newObj.roleName = undefined;
        this.userForm = newObj;
      } else {
        this.backupData = {};
        for (let key in this.userForm) {
          this.userForm[key] = undefined;
          this.backupData[key] = undefined;
        }
      }
      this.userEditVisiable = true;
      this.viewFormType = model;
    },
    onClose() {
      this.loading = false;
      this.userEditVisiable = false;
      this.reset();
      this.$emit("close");
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if(valid) {
          let user = this.userForm

          if(this.viewFormType) {
            this.loading = true
            this.$put('user', {
              ...user
            }).then((r) => {
              this.loading = false
              this.$message.success("编辑用户成功");
              this.userEditVisiable = false;
              this.$emit('success')
              // 如果修改用户就是当前登录用户的话，更新其state
              if (user.username === this.currentUser.username) {
                this.$get(`user/${user.username}`).then((r) => {
                  this.setUser(r.data)
                })
              }
            }).catch(() => {
              this.loading = false
            })
          } else {
            this.loading = true
            this.$post('user', {
              ...user
            }).then((r) => {
              this.loading = false
              this.$message.success("新增用户成功，初始密码为123456");
              this.userEditVisiable = false;
              this.$emit('success')
              this.reset()
            }).catch(() => {
              this.loading = false
            })
          }
        }
      });
    },
    reset() {
      if (this.backupData) {
        for (let key in this.backupData) {
          this.userForm[key] = this.backupData[key];
        }
      }
    },
    clear() {
      for (let key in this.userForm) {
        this.userForm[key] = undefined;
      }
    },
    changeUsername() {
      this.userForm.usernameEn = pinyin.getFullChars(this.userForm.username)
    }
  },
  computed: {
    topDeptId() {
      let type = this.$route.path.split("/")[2];
      return getDeptRootId(type)
    },
    currentDeptPosition() {
      let dp = (this.deptPositionListData || []).find(item => item.deptPositionId == this.userForm.deptPositionId)
      return dp;
    },
    roleDataFiltered() {
      let dp = this.currentDeptPosition;
      let result = []
      if (dp && this.roleData) {
        result = this.roleData.filter(item => ((dp.prepareRoles || "").split(",").indexOf(String(item.roleId)) >= 0))
      }
      console.log("-my roles-", JSON.stringify(result, null, 2))
      return result;
    },
    userFormRoleIdSplited() {
      if (this.userForm && this.userForm.roleId) {
        return this.userForm.roleId.split(",");
      }
      return [];
    },
    personalTitleKeySplited() {
      if (this.userForm && this.userForm.personalTitleKey) {
        return this.userForm.personalTitleKey.split(",");
      }
      return [];
    },
    // personalTitleKeySplited: {
    //   get: function () {
    //     if (this.userForm && this.userForm.personalTitleKey) {
    //       return this.userForm.personalTitleKey.split(",");
    //     }
    //     return [];
    //   },
    //   set: function (newValue) {
    //     console.log(newValue)
    //     this.userForm.personalTitleKey = newValue.join(',');
    //   }
    // },
  },
  watch: {
    "userForm.deptId"() {
      if (this.userForm.deptId) {
        this.$get("dept-position", { deptId: this.userForm.deptId }).then(
          (r) => {
            this.deptPositionListData = r.data.rows;
          }
        );
      }
    }
  },
};
</script>
